import { render, staticRenderFns } from "./EventDescription.vue?vue&type=template&id=43ef1673"
import script from "./EventDescription.vue?vue&type=script&lang=js"
export * from "./EventDescription.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports