<script>
import {
  SuawDiscussionList,
  SuawDiscussionItem,
  SuawResponseList,
  SuawResponseItem,
  SuawReplyList,
  SuawReplyItem,
  SuawEmptyState,
  SuawModal,
  SuawLabel,
  SuawEditorBox,
  SuawInputGroup,
  SuawButton,
  SuawListSection,
  SuawSectionHeader
} from "@suaw/suaw-component-library";
import { GetThreadsByChannelId } from "../operations.gql";
import * as DiscussionApi from "../api.js";
import { formatDistanceToNow } from "date-fns";

export default {
  name: "DiscussionListPipe",
  components: {
    SuawDiscussionList,
    SuawDiscussionItem,
    SuawResponseList,
    SuawResponseItem,
    SuawReplyList,
    SuawReplyItem,
    SuawEmptyState,
    SuawModal,
    SuawLabel,
    SuawEditorBox,
    SuawInputGroup,
    SuawButton,
    SuawListSection,
    SuawSectionHeader
  },
  props: {
    channelId: {
      type: String,
      required: true
    },
    threadCount: {
      type: Number,
      default: 0
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    headerLabel: {
      type: String,
      default: "Label"
    },
    buttonLabel: {
      type: String,
      default: "View All"
    },
    showEditor: {
      type: Boolean,
      default: false
    }
    // showSeeAll: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      resultGetThreadsByChannelId: {
        top_level_threads: [
          // {
          //   id: "",
          //   thread_depth: 0,
          //   threads_posts: [
          //     {
          //       id: "",
          //       thread_id: "",
          //       drafted_at: null,
          //       posted_at: null,
          //       deleted_at: null,
          //       last_edited_at: null,
          //       body_json: "",
          //       author: {
          //         id: "",
          //         avatar_url: "",
          //         avatar_color: "",
          //         first_name: "",
          //         display_name: "",
          //         initials: "",
          //         deleted_at: null
          //       },
          //       posts_threads: [
          //         {
          //           id: "",
          //           parent_post_id: "",
          //           thread_depth: 0,
          //           threads_posts: [
          //             {
          //               id: "",
          //               thread_id: "",
          //               drafted_at: null,
          //               posted_at: null,
          //               deleted_at: null,
          //               last_edited_at: null,
          //               body_json: null,
          //               author: {
          //                 id: "",
          //                 avatar_url: "",
          //                 avatar_color: "",
          //                 first_name: "",
          //                 display_name: "",
          //                 initials: "",
          //                 deleted_at: null
          //               },
          //               posts_threads: [
          //                 {
          //                   id: "",
          //                   parent_post_id: "",
          //                   thread_depth: 0,
          //                   threads_posts: [
          //                     {
          //                       id: "",
          //                       thread_id: "",
          //                       drafted_at: null,
          //                       posted_at: null,
          //                       deleted_at: null,
          //                       last_edited_at: null,
          //                       body_json: null,
          //                       author: {
          //                         id: "",
          //                         avatar_url: "",
          //                         avatar_color: "",
          //                         first_name: "",
          //                         display_name: "",
          //                         initials: "",
          //                         deleted_at: null
          //                       }
          //                     }
          //                   ]
          //                 }
          //               ]
          //             }
          //           ]
          //         }
          //       ]
          //     }
          //   ]
          // }
        ]
      },
      showModal: false,
      modalTitle: "",
      modalConfirmAction: null,
      topLevelPostContent: { type: "doc", content: [{ type: "paragraph" }] }
    };
  },
  computed: {
    isAuthenticated() {
      return this.$auth && this.$auth.isAuthenticated;
    },
    userId() {
      return this.isAuthenticated ? this.$auth.user.id : null;
    },
    hasThreads() {
      return (
        this.resultGetThreadsByChannelId &&
        this.resultGetThreadsByChannelId.top_level_threads &&
        this.resultGetThreadsByChannelId.top_level_threads.length > 0 &&
        this.resultGetThreadsByChannelId.top_level_threads[0].id !== ""
      );
    },
    discussions() {
      if (this.hasThreads) {
        return this.transformDiscussions(this.resultGetThreadsByChannelId.top_level_threads);
      } else {
        return [];
      }
    },
    slicedDiscussions() {
      if (this.threadCount > 0 && this.discussions.length > this.threadCount) {
        return this.discussions.slice(0, this.threadCount);
      }
      return this.discussions;
    },
    showShowAll() {
      return this.threadCount !== 0;
    },
    showAllLabel() {
      return this.showShowAll ? "See All Discussions" : null;
    },
    showAllUrl() {
      return "?active_tab=2";
    }
  },
  apollo: {
    resultGetThreadsByChannelId: {
      query: GetThreadsByChannelId,
      variables() {
        return {
          channelId: this.channelId,
          includeReactions: !!this.userId
        };
      },
      skip() {
        return !this.channelId;
      }
    }
  },
  methods: {
    onCancelModal() {
      this.showModal = false;
    },
    getRelativeTime(posted_at) {
      const result = formatDistanceToNow(new Date(posted_at), { addSuffix: true });
      return result;
    },
    transformDiscussions(topLevelThreads) {
      return topLevelThreads.flatMap(thread =>
        thread.threads_posts.map(discussion => {
          const responses =
            discussion.posts_threads && discussion.posts_threads.length > 0 ? this.transformResponses(discussion.posts_threads[0].threads_posts ?? [], discussion.id) : [];
          return this.transformPost(discussion, responses);
        })
      );
    },
    transformResponses(postsThreads, parentId) {
      if (!postsThreads || postsThreads.length === 0) return [];
      return postsThreads.map(response => {
        const replies = response.posts_threads && response.posts_threads.length > 0 ? this.transformReplies(response.posts_threads[0].threads_posts ?? [], response.id) : [];
        return this.transformPost(response, replies, parentId);
      });
    },
    transformReplies(postsThreads, parentId) {
      if (!postsThreads || postsThreads.length === 0) return [];
      return postsThreads.map(reply => {
        return this.transformPost(reply, [], parentId);
      });
    },
    transformPost(post, childThreads = [], parentPostId = null) {
      const isRemoved = post.deleted_at !== null || post.author.deleted_at !== null;
      const isEdited = !!post.last_edited_at;
      const removedPostBodyJson = {
        type: "doc",
        content: [{ type: "paragraph", content: [{ type: "text", text: "This post has been hidden.", marks: [{ type: "italic" }] }] }]
      };
      // let aColor = post.author.avatar_color ? post.author.avatar_color : "blue";
      // let aType = !post.author.is_avatar_hidden && post.author.avatar_url ? "picture" : aColor;
      // let aAvatar = !post.author.is_avatar_hidden && aType === "picture" ? post.author.avatar_url : post.author.initials;
      const likeCount = post.post_reactions_aggregate?.aggregate?.count || 0;
      const isLiked = this.isAuthenticated && post.post_reactions?.length > 0;

      return {
        id: post.id,
        text: isRemoved ? removedPostBodyJson : post.body_json,
        author: {
          id: post.author.id,
          // avatar: isRemoved ? null : aAvatar,
          // name: isRemoved ? "Community Member" : post.author.display_name
          avatarUrl: post.author.avatar_url,
          avatarColor: post.author.avatar_color,
          avatarInitials: post.author.initials,
          isAvatarHidden: post.author.is_avatar_hidden,
          displayName: post.author.display_name
        },
        threadId: post.thread_id,
        isRemoved: isRemoved,
        posted: isEdited ? `${this.getRelativeTime(post.posted_at)} (edited ${this.getRelativeTime(post.last_edited_at)})` : this.getRelativeTime(post.posted_at),
        parentPostId: parentPostId,
        childThreadCount: childThreads.length,
        childThreads: childThreads,
        likeCount: likeCount,
        isLiked: isLiked
      };
    },
    discussionDropdownType(authorId, postId, isPostRemoved) {
      const { user, isAuthenticated } = this.$auth;
      const isUserAdmin = user?.role === "app_admin";
      const isOwnPost = authorId === user?.id;

      if (!isAuthenticated || (!isUserAdmin && isPostRemoved)) return [];

      const options = [];

      if (isUserAdmin) {
        if (isPostRemoved) {
          options.push({ icon: "IconShow", text: "Unremove", postId, authorId });
        } else {
          if (isOwnPost) {
            options.push({ icon: "IconEdit", text: "Edit", postId, authorId }, { icon: "IconHide", text: "Remove", postId, authorId });
          } else {
            options.push({ icon: "IconUserVoice", text: "Report", postId, authorId }, { icon: "IconHide", text: "Remove", postId, authorId });
          }
        }
      } else {
        if (isOwnPost) {
          options.push({ icon: "IconEdit", text: "Edit", postId, authorId }, { icon: "IconHide", text: "Remove", postId, authorId });
        } else {
          options.push({ icon: "IconUserVoice", text: "Report", postId, authorId });
        }
      }

      return options;
    },
    onPostDropdownOptionClick(option) {
      if (option.text === "Remove") {
        this.showModal = true;
        this.modalTitle = "Remove this post?";
        this.modalConfirmAction = () => this.onRemovePost(option.postId);
      } else if (option.text === "Unremove") {
        this.showModal = true;
        this.modalTitle = "Unremove this post?";
        this.modalConfirmAction = () => this.onUnremovePost(option.postId);
      } else if (option.text === "Report") {
        this.handleReportClick(option.postId);
      }
    },
    async onCreateDiscussion(postContent) {
      return await this.onCreatePost(postContent);
    },
    async onCreateResponseOrReply(post) {
      return await this.onCreatePost(post.text, post.parentPostId);
    },
    async onCreatePost(postContent, parentPostId) {
      const draftPostResult = await DiscussionApi.draftPost(this.channelId, postContent, parentPostId);
      if (!draftPostResult.success) {
        this.$root.$emit("universal-error-message", draftPostResult.error);
        return;
      }
      const commitPostResult = await DiscussionApi.commitPost(draftPostResult.result.id);
      if (!commitPostResult.success) {
        this.$root.$emit("universal-error-message", commitPostResult.error);
        return;
      }
      this.$apollo.queries.resultGetThreadsByChannelId.refetch();
    },
    async onEditPost(post) {
      const editPostResult = await DiscussionApi.editPost(post.id, post.text);
      if (!editPostResult.success) {
        this.$root.$emit("universal-error-message", editPostResult.error);
      }
      this.$apollo.queries.resultGetThreadsByChannelId.refetch();
    },
    async onRemovePost(postId) {
      const removePostResult = await DiscussionApi.removePost(postId);
      if (!removePostResult.success) {
        this.$root.$emit("universal-error-message", removePostResult.error);
      }
      this.showModal = false;
      this.modalTitle = null;
      this.$apollo.queries.resultGetThreadsByChannelId.refetch();
    },
    async onUnremovePost(postId) {
      const unremovePostResult = await DiscussionApi.unremovePost(postId);
      if (!unremovePostResult.success) {
        this.$root.$emit("universal-error-message", unremovePostResult.error);
      }
      this.showModal = false;
      this.modalTitle = null;
      this.$apollo.queries.resultGetThreadsByChannelId.refetch();
    },
    handleReportClick(postId) {
      const complaintContext = {
        context: "Post",
        postId: postId
      };
      this.$root.$emit("universal-complaint", complaintContext);
    },
    async onLikeClick({ id: postId, isLiked }) {
      let result;
      if (!this.isAuthenticated) return;
      if (isLiked) {
        result = await DiscussionApi.unstampPost(postId, this.$auth.user.id, "Like");
      } else {
        result = await DiscussionApi.stampPost(postId, this.$auth.user.id, "Like");
      }
      if (result.success) {
        await this.$apollo.queries.resultGetThreadsByChannelId.refetch();
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    }
  }
};
</script>

<template>
  <SuawInputGroup group-gap="double" :centered="true" direction="column">
    <SuawModal v-if="showModal" :modal-title="modalTitle" modal-type="confirmation" :toggle-button-confirm="modalConfirmAction" :toggle-button-cancel="onCancelModal" />
    <SuawEditorBox
      v-if="showEditor"
      style="margin-bottom: 24px;"
      form-id="item"
      button-text="Create Discussion"
      input-message="Create a discussion post here."
      @submit="onCreateDiscussion"
    />
    <SuawSectionHeader v-if="showHeader" :header-label="headerLabel" :button-label="buttonLabel" @view-all-click="$emit('view-all-click')" />
    <SuawListSection v-if="hasThreads">
      <SuawDiscussionItem
        v-for="discussion in slicedDiscussions"
        :id="discussion.id"
        :key="discussion.id"
        class="discussion-item"
        :card-text="discussion.text"
        :chip-content="discussion.author.avatar"
        :chip-text="discussion.author.name"
        :avatar-url="discussion.author.avatarUrl"
        :avatar-color="discussion.author.avatarColor"
        :avatar-initials="discussion.author.avatarInitials"
        :is-avatar-hidden="discussion.author.isAvatarHidden"
        :display-name="discussion.author.displayName"
        :posted="discussion.posted"
        :like-count="discussion.likeCount"
        :is-liked="discussion.isLiked"
        :response-number="discussion.childThreadCount"
        :show-discussion-likes="true"
        :dropdown-items="discussionDropdownType(discussion.author.id, discussion.id, discussion.isRemoved)"
        editor-box-primary-button-text="Edit Discussion"
        editor-box-secondary-button-text="Cancel"
        @edit-discussion="onEditPost"
        @submit-response="onCreateResponseOrReply"
        @dropdown-item-click="onPostDropdownOptionClick"
        @like-click="onLikeClick"
      >
        <SuawResponseList :response-number="discussion.childThreadCount">
          <li v-for="response in discussion.childThreads" :key="response.id" class="suaw-discussion-list__item">
            <SuawResponseItem
              :id="response.id"
              :parent-post-id="response.parentPostId"
              :heading-text="response.text"
              :chip-content="response.author.avatar"
              :chip-text="response.author.name"
              :avatar-url="response.author.avatarUrl"
              :avatar-color="response.author.avatarColor"
              :avatar-initials="response.author.avatarInitials"
              :is-avatar-hidden="response.author.isAvatarHidden"
              :display-name="response.author.displayName"
              :posted="response.posted"
              :reply-number="response.childThreadCount"
              :like-count="response.likeCount"
              :is-liked="response.isLiked"
              :show-discussion-likes="true"
              :dropdown-items="discussionDropdownType(response.author.id, response.id, response.isRemoved)"
              text-box-primary-button="Edit Response"
              text-box-secondary-button="Cancel"
              @edit-response="onEditPost"
              @submit-reply="onCreateResponseOrReply"
              @dropdown-item-click="onPostDropdownOptionClick"
              @like-click="onLikeClick"
            >
              <SuawReplyList>
                <SuawReplyItem
                  v-for="reply in response.childThreads"
                  :id="reply.id"
                  :key="reply.id"
                  :parent-post-id="reply.parentPostId"
                  :card-text="reply.text"
                  :chip-content="reply.author.avatar"
                  :chip-text="reply.author.name"
                  :avatar-url="reply.author.avatarUrl"
                  :avatar-color="reply.author.avatarColor"
                  :avatar-initials="reply.author.avatarInitials"
                  :is-avatar-hidden="reply.author.isAvatarHidden"
                  :display-name="reply.author.displayName"
                  :posted="reply.posted"
                  :reply-number="reply.childThreadCount"
                  :like-count="reply.likeCount"
                  :is-liked="reply.isLiked"
                  :show-discussion-likes="true"
                  :dropdown-items="discussionDropdownType(reply.author.id, reply.id, reply.isRemoved)"
                  text-box-primary-button="Edit Reply"
                  text-box-secondary-button="Cancel"
                  @edit-reply="onEditPost"
                  @dropdown-item-click="onPostDropdownOptionClick"
                  @like-click="onLikeClick"
                />
              </SuawReplyList>
            </SuawResponseItem>
          </li>
        </SuawResponseList>
      </SuawDiscussionItem>
    </SuawListSection>
    <!-- <SuawDiscussionList v-if="hasThreads" :show-view-all="false" :discussions-label="showAllLabel" :discussions-url="showAllUrl">
    </SuawDiscussionList> -->
    <SuawEmptyState v-else message="No Discussions" />
    <!-- <SuawButton v-if="showSeeAll" class="suaw-see-all-members-button" type="ghost" size="large" button-text="See All Discussions" @click="$emit('see-all-discussions')" /> -->
  </SuawInputGroup>
</template>
