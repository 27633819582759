<script>
import { SuawCalendarCard, SuawEmptyState } from "@suaw/suaw-component-library";
import { UpcomingEvents, UpcomingEventDates, GetStartTimeByEventId } from "../../../events/operations.gql";
import { add } from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";

export default {
  name: "CalendarCardPipe",
  components: { SuawCalendarCard, SuawEmptyState },
  props: {
    seriesId: {
      type: String,
      required: true
    },
    eventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingQueriesCount: 0,
      eventDates: [],
      asOfDate: "",
      selectedEventDate: null
    };
  },
  apollo: {
    resultGetStartTimeByEventId: {
      query: GetStartTimeByEventId,
      variables() {
        return {
          eventId: this.eventId
        };
      },
      skip() {
        return !this.eventId;
      },
      update(data) {
        this.selectedEventDate = this.convertUtcToLocalIsoString(data.resultGetStartTimeByEventId.starts_at);
      },
      loadingKey: "loadingQueriesCount"
    },
    resultUpcomingEventDates: {
      query: UpcomingEventDates,
      variables() {
        return {
          eventSeriesId: this.seriesId,
          timeZoneId: this.userTimeZone
        };
      },
      update(data) {
        if (data.resultUpcomingEventDates.value.length > 0) {
          this.eventDates = data.resultUpcomingEventDates.value;
        }
      },
      skip() {
        return !this.seriesId;
      },
      loadingKey: "loadingQueriesCount"
    },
    resultUpcomingEvents: {
      query: UpcomingEvents,
      variables() {
        return {
          eventSeriesId: this.seriesId,
          asOfDate: this.asOfDate,
          untilDate: this.calculateUntilDate(this.asOfDate)
        };
      },
      update(data) {
        if (data.resultUpcomingEvents.value.length > 0) {
          this.$emit("event-selected", data.resultUpcomingEvents.value[0]);
        }
      },
      skip() {
        return !this.seriesId || !this.asOfDate;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    userTimeZone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone || "Etc/Universal";
    }
  },
  methods: {
    convertUtcToLocalIsoString(utcDateString) {
      const localDate = utcToZonedTime(utcDateString, this.userTimeZone);
      return format(localDate, "yyyy-MM-dd'T'HH:mm:ss");
    },
    calculateUntilDate(isoString) {
      const date = new Date(isoString);
      const updatedDate = add(date, { hours: 24 });
      return updatedDate.toISOString();
    },
    onDateSelected(eventDate) {
      this.asOfDate = eventDate;
    }
  }
};
</script>

<template>
  <div>
    <SuawCalendarCard v-if="!isLoading" :event-dates="eventDates" :selected-date="selectedEventDate" @date-selected="onDateSelected" />
    <SuawEmptyState v-else message="Loading event calendar..." />
  </div>
</template>
