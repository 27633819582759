<script>
import { SuawLabel, SuawList, SuawInputGroup } from "@suaw/suaw-component-library";
import { GetChaptersByIds } from "../../operations.gql";
import { ListItemItemFactory } from "@/utils/factories/listItemProps.js";

export default {
  name: "ChapterSidebar",
  components: {
    SuawLabel,
    SuawList,
    SuawInputGroup
  },
  props: {
    headerText: {
      type: String,
      required: true
    },
    chapterIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      resultGetChaptersByIds: [
        // {
        //   id: "",
        //   slug: "",
        //   title: "",
        //   photo_url: ""
        // }
      ]
    };
  },
  apollo: {
    resultGetChaptersByIds: {
      query: GetChaptersByIds,
      variables() {
        return {
          ids: this.chapterIds
        };
      },
      skip() {
        return !this.chapterIds.length;
      }
    }
  },
  computed: {
    listItems() {
      let factory = Object.create(ListItemItemFactory);
      return this.resultGetChaptersByIds.map(ch => {
        return factory
          .clean()
          .withChapterObject(ch)
          .withLink({ name: "Chapter", params: { chapterId: ch.id, chapterSlug: ch.slug } }, "View Chapter")
          .build();
      });
    }
  }
};
</script>

<template>
  <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true" direction="column">
    <SuawLabel size="large" :label-text="headerText" weight="bold" />
    <SuawList :items="listItems" />
  </SuawInputGroup>
</template>
